import { Fragment } from 'react'
import { OptionItemInterface } from './OptionItem.interface'
import { Col } from 'react-bootstrap'
import { ReadMore, Heading } from '../../atoms'
import { Link } from 'react-router-dom'

const OptionItem = ({ testID, item }: OptionItemInterface) => {
  return (
    <Col
      data-testid={testID}
      className={`OptionItem`}
      key={`tile-${item?.id}`}
      md={3}
      sm={6}
      xs={6}
    >
      <a href={item?.link}>
        <img src={item?.img} alt={item?.title} />
      </a>
      <Heading type={'h3'} text={item?.title} />
      <ReadMore link={item?.link} type={'inline'} />
    </Col>
  )
}

export default OptionItem
