import { useState } from 'react'
import { ContactFormInterface } from './ContactForm.interface'
import { Button, Heading, InputField, TextArea, ThankYou } from '../../atoms'
import { Row, Col } from 'react-bootstrap'
import { saveContact } from 'src/lib/'

/// TODO: update interface/arguments for ContactForm
const ContactForm = ({ testID }: ContactFormInterface) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [msg, setMsg] = useState('')
  const [showThanks, setShowThanks] = useState(false)

  const date = new Date(Date.now())

  const sendMessage = async () => {
    /// TODO: CHECK INBOUWEN!!!
    const site = 'thiago'
    await saveContact({
      site,
      date,
      name,
      email,
      phone,
      msg
    })

    fetch('https://formsubmit.co/ajax/app.thiago.guilhermo@gmail.com', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: msg
      })
    })
      .then((response) => response.json())
      .then((data) => setShowThanks(true))
      .catch((error) => console.log(error))
      .finally(() => setShowThanks(true))

    //setShowThanks(true)
  }

  const renderContent = () => {
    if (showThanks)
      return (
        <Col md={8}>
          <ThankYou />
        </Col>
      )

    return (
      <Col md={8}>
        <Heading type={'h2'} text={'Neem contact met ons op'} />

        <InputField
          type={'text'}
          name={'naam'}
          required={true}
          placeholder={'Naam *'}
          value={name}
          onChange={setName}
        />
        <InputField
          type={'text'}
          name={'email'}
          required={true}
          placeholder={'Email adres *'}
          value={email}
          onChange={setEmail}
        />
        <InputField
          type={'text'}
          name={'telefoonnr'}
          required={false}
          placeholder={'Telefoonnummer'}
          value={phone}
          onChange={setPhone}
        />
        <TextArea
          required={true}
          name={'bericht'}
          placeholder={'Uw bericht *'}
          value={msg}
          onChange={setMsg}
        />
        <div className={'float-right'}>
          <Button text={'Contact opnemen'} action={sendMessage} />
        </div>
      </Col>
    )
  }

  return (
    <div data-testid={testID} className={`ContactForm`}>
      <Row>
        <Col md={2} />
        {renderContent()}
        <Col md={2} />
      </Row>
    </div>
  )
}

export default ContactForm
