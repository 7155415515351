import { FooterInterface } from './Footer.interface'
import { Container, Row, Col } from 'react-bootstrap'
import { Logo } from 'src/components/atoms'
import { config } from 'src/config'
import { FooterColumn } from 'src/components/molecules'
import { SubscribeNewsletter } from '../../molecules'

const Footer = ({ testID }: FooterInterface) => {
  return (
    <section data-testid={testID} className={`Footer`}>
      <Container>
        <Row>
          <Col md={2}>
            <Logo type={'footer'} />
          </Col>
          <Col md={10}>
            <Row>
              {config.footerCols.map((col, index) => {
                return (
                  <Col md={4} key={`col-${index}`}>
                    <FooterColumn column={col} />
                    {index === 2 && <SubscribeNewsletter />}
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Footer
