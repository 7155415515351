// import { useState, useEffect, useContext } from 'react'
import { ButtonInterface } from './Button.interface'

const Button = ({
  testID,
  text,
  action,
  type = 'default'
}: ButtonInterface) => {
  const handleClick = (): void => {
    action && action()
  }

  return (
    <div
      onClick={() => handleClick()}
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Button`}
    >
      {text}
    </div>
  )
}

export default Button
