import { config } from 'src/config'
const pageImages = {
  'home': require('../resources/images/bg-home.jpeg'),
  'info': require('../resources/images/bg-home.jpeg'),
  'customs': require('../resources/images/bg-customs.jpeg'),
  'modellen': require('../resources/images/bg-modellen.jpeg'),
  'materialen-en-kleuren': require('../resources/images/bg-materialen.png'),
  'bijpassende-riem': require('../resources/images/bg-riemen.png')
}

export default pageImages

export const checkType = (path: string): any => {
  const l = path.replace('/', '').split('/')
  let type = 'home'
  let collection
  let image = pageImages.home

  if (l[0] === 'informatie' || l[0] === 'concept') {
    type = 'detail'
  }

  const types = [
    'bijpassende-riem',
    'materialen-en-kleuren',
    'modellen',
    'customs'
  ]

  if (l[1] && types.includes(l[1])) {
    let p: string = l[1].toString()
    switch (p) {
      case 'customs': {
        image = pageImages['customs']
        collection = `${config.prefix}_customs`
        break
      }
      case 'materialen-en-kleuren': {
        image = pageImages['materialen-en-kleuren']
        collection = `${config.prefix}_materials`
        break
      }
      case 'modellen': {
        image = pageImages['modellen']
        collection = `${config.prefix}_models`
        break
      }
      case 'bijpassende-riem': {
        image = pageImages['bijpassende-riem']
        collection = `${config.prefix}_belts`
        break
      }
      default: {
        image = pageImages['home']
        collection = 'info'
      }
    }
    //image = pageImages['modellen']
  }

  return [type, image, collection]
}
