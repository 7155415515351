import { ReadMoreInterface } from './ReadMore.interface'
import { config } from '../../../config'
import { Link } from 'react-router-dom'

const ReadMore = ({ testID, type = 'inline', link }: ReadMoreInterface) => {
  return (
    <span className='ReadMore'>
      <a href={link} data-testid={testID} data-object-type={type ?? ''}>
        {config.readMore}
      </a>
    </span>
  )
}

export default ReadMore
