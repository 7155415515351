import { useState, useEffect, useContext } from 'react'
import { HomeInterface } from './Home.interface'
import { Section } from 'src/components/organisms'
import { Banner, Options } from 'src/components/molecules'
import { AppContext } from 'src/context'
import { ContactForm } from '../../organisms'

const Home = ({ testID }: HomeInterface) => {
  const ctx = useContext(AppContext)

  const pageData = ctx.data.find((pg: any) => pg.type === 'homepage')

  return (
    <div data-testid={testID} className={`Home`}>
      {pageData?.pageContent?.map((pageItem: any, index: number) => {
        return (
          <>
            <Section
              id={pageItem.color === 'dark' ? 'over' : ''}
              type={pageItem.color === 'dark' ? 'info' : 'content'}
              data={pageItem}
            ></Section>
            {pageItem.list === 'concept' && (
              <Section id={'concept'} type={'content'} data={[]}>
                <Options data={ctx.tiles} />
              </Section>
            )}
          </>
        )
      })}
      <Section id='contact' type={'form'} data={[]}>
        <ContactForm />
      </Section>
    </div>
  )
}

export default Home
