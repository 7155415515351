import { ParagraphInterface } from './Paragraph.interface'
//import Markdown from 'react-markdown'
//import remarkGfm from 'remark-gfm'

import MarkdownView from 'react-showdown'

const Paragraph = ({ testID, type, content }: ParagraphInterface) => {
  const renderMarkdown = () => {
    if (type === 'markdown') {
      return (
        <MarkdownView
          markdown={content}
          options={{ tables: true, emoji: true }}
        />
      )
    }
  }

  const renderPlainText = () => {
    if (type === 'plain-text') {
      return <p>{content}</p>
    }
  }

  const renderHtmlText = () => {
    if (type === 'html') {
      return <div dangerouslySetInnerHTML={{ __html: content }} />
    }
  }

  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Paragraph`}
    >
      {renderMarkdown()}
      {renderPlainText()}
      {renderHtmlText()}
    </div>
  )
}

export default Paragraph
