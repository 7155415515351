import { FooterListItemInterface } from './FooterListItem.interface'

const FooterListItem = ({ testID, item, type }: FooterListItemInterface) => {
  const renderItem = () => {
    if (type === 'string') return <span>{item.prompt}</span>
    if (type === 'link') return <a href={item.value}>{item.prompt}</a>
    if (type === 'phone')
      return (
        <>
          {item.prompt}: <a href={`tel:${item.value}`}>{item.value}</a>
        </>
      )
    if (type === 'mail')
      return <a href={`mailto:${item.value}`}>{item.prompt}</a>
  }
  return (
    <span
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`FooterListItem`}
    >
      {renderItem()}
    </span>
  )
}

export default FooterListItem
