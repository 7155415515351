// import { useState, useEffect, useContext } from 'react'
import { HelmetInterface } from './Helmet.interface'
import { Helmet } from 'react-helmet-async'
import { config } from 'src/config'

const HelmetSrc = ({ title, description }: HelmetInterface) => {
  return (
    <Helmet>
      <title>{`${config.siteName} :: ${title}`}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
    </Helmet>
  )
}

export default HelmetSrc
