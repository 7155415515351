import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Main } from 'src/components/templates'
import { Home, Content } from 'src/components/pages'
import { AppContext } from 'src/context'
import { config } from './config'
import { ScrollToAnchor } from 'src/components/organisms'

import { useDatabase } from 'src/hooks'

const App = () => {
  const tiles = config.tiles
  const [data, isLoaded] = useDatabase('thiago_pages')

  const renderRoutes = () => {
    if (isLoaded)
      return (
        <AppContext.Provider value={{ data, tiles }}>
          <Routes>
            <Route element={<Main />}>
              <Route index path='/' element={<Home />} />
              <Route path='/concept/:slug' element={<Content />} />
              <Route path='/informatie/:slug' element={<Content />} />
            </Route>
          </Routes>
          <ScrollToAnchor />
        </AppContext.Provider>
      )
  }
  return <BrowserRouter>{renderRoutes()}</BrowserRouter>
}

export default App
