import { ProductItemInterface } from './ProductItem.interface'

const ProductItem = ({ testID, image, alt }: ProductItemInterface) => {
  return (
    <div data-testid={testID} className={`ProductItem`}>
      <img src={image} alt={alt} />
    </div>
  )
}

export default ProductItem
