import { BannerInterface } from './Banner.interface'
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax'

const Banner = ({ testID, children, type, image }: BannerInterface) => {
  return (
    <ParallaxProvider>
      <ParallaxBanner
        layers={[
          {
            image: image,
            speed: -20
          },
          {
            children: children
          }
        ]}
        className='Banner'
        data-object-type={type ?? 'home'}
      />
    </ParallaxProvider>
  )
}

export default Banner
