// import { useState, useEffect, useContext } from 'react'
import { Heading } from 'src/components/atoms'
import { ThankYouInterface } from './ThankYou.interface'

const ThankYou = ({ testID }: ThankYouInterface) => {
  return (
    <div data-testid={testID} className={`ThankYou`}>
      <Heading type={'h2'} text={'Dank u voor uw interesse in Thiago Shoes'} />
    </div>
  )
}

export default ThankYou
