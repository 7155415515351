// import { useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Outlet, useLocation } from 'react-router-dom'
import { MainInterface } from './Main.interface'
import { Footer, Header } from '../../organisms'
import { checkType } from 'src/config'

const Main = ({ testID }: MainInterface) => {
  const location = useLocation().pathname
  const [type, image] = checkType(location)

  return (
    <Container fluid style={{ padding: 0 }} data-testid={testID}>
      <Header type={type} image={image} />
      <Outlet />
      <Footer />
    </Container>
  )
}

export default Main
