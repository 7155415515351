// import { useState, useEffect, useContext } from 'react'
import { TextAreaInterface } from './TextArea.interface'

/// TODO: update interface/arguments for TextArea
const TextArea = ({
  name,
  required,
  placeholder,
  value,
  onChange,
  testID
}: TextAreaInterface) => {
  const changeHandler = (v: string) => {
    onChange(v)
  }

  return (
    <textarea
      data-testid={testID}
      placeholder={placeholder}
      onChange={(e) => changeHandler(e.target.value)}
      className={`TextArea`}
      rows={6}
    >
      {value}
    </textarea>
  )
}

export default TextArea
