import { OptionsInterface } from './Options.interface'
import { Row } from 'react-bootstrap'
//import { OptionItem } from 'src/components/molecules'
import OptionItem from 'src/components/molecules/OptionItem'
import { itemType } from 'src/config'

const Options = ({ testID, data }: OptionsInterface) => {
  return (
    <Row className={`Options`} data-testid={testID}>
      {data?.map((tile: itemType, index: number) => {
        return <OptionItem key={`option-${index}`} item={tile} />
      })}
    </Row>
  )
}

export default Options
