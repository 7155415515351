// import { useState, useEffect, useContext } from 'react'
import { HeaderInterface } from './Header.interface'
import { Container, Row, Col } from 'react-bootstrap'
import { Logo } from 'src/components/atoms'
import { Banner } from 'src/components/molecules'
import { config } from 'src/config'
import { Menu } from '../../molecules'

const Header = ({ testID, type, image }: HeaderInterface) => {
  return (
    <Banner type={type} image={image}>
      <Container
        data-testid={testID}
        data-object-type={type ?? ''}
        className={`Header`}
      >
        <Row>
          <Col md={8}>
            <Logo type={type} />
          </Col>
          <Col md={4}>
            <Menu />
          </Col>
        </Row>
        <Row>
          <Col md={5} />
          <Col md={6} className={'slogan'}>
            {config.slogan}
          </Col>
          <Col md={1} />
        </Row>
      </Container>
    </Banner>
  )
}

export default Header
