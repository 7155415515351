import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import { ContentInterface } from './Content.interface'
import { AppContext } from 'src/context'
import { checkType, config } from 'src/config'
import { useDatabase } from 'src/hooks'

import { Spinner } from 'src/components/atoms'
import { Options } from 'src/components/molecules'
import { Section, ProductList } from 'src/components/organisms'
import { Row, Col } from 'react-bootstrap'

const Content = ({ testID }: ContentInterface) => {
  const ctx = useContext(AppContext)
  const location = useLocation().pathname + '/'
  //const [type, image, collection] = checkType(location)
  //const [data, isLoaded] = useDatabase(collection)

  // const renderProductList = () => {
  //   if (isLoaded) return
  //   return <Spinner color={'#000'} />
  // }

  const pageData = ctx.data.find(
    (pg: any) => pg.slug === location.replace('//', '/')
  )

  return (
    <div data-testid={testID} className={`Content`}>
      {pageData?.pageContent?.map((pageItem: any, index: number) => {
        return (
          <>
            <Section
              id={pageItem.color === 'dark' ? 'over' : ''}
              type={pageItem.color === 'dark' ? 'info' : 'content'}
              data={pageItem}
            ></Section>
            {pageItem.list !== null && pageItem.list !== 'nolist' ? (
              <Row>
                <Col md={2} />
                <Col md={8}>
                  <ProductList
                    collection={`${config.prefix}_${pageItem.list}`}
                  />
                </Col>
                <Col md={2} />
              </Row>
            ) : null}
          </>
        )
      })}

      {/*{collection && <ProductList collection={collection} />}*/}
      <Section id={'concept'} type={'content'} data={[]}>
        <Options data={ctx.tiles} />
      </Section>
    </div>
  )
}

export default Content
