import { HeadingInterface } from './Heading.interface'

const Heading = ({ testID, type = 'h2', text }: HeadingInterface) => {
  const CustomTag: any = type?.replace(' ', '')

  return (
    <CustomTag
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Heading`}
    >
      {text}
    </CustomTag>
  )
}

export default Heading
