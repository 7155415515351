import TileModel from '../resources/images/shoe.png'
import TileMaterial from '../resources/images/materialen.png'
import TileBelt from '../resources/images/riem.png'
import TileCustom from '../resources/images/customs.png'

export type itemType = {
  id: number
  title: string
  link: string
  img: any
}

const siteName = 'Thiago Shoes'
const readMore = 'lees meer...'

const tiles = [
  { id: 1, title: 'Modellen', link: '/concept/modellen/', img: TileModel },
  {
    id: 2,
    title: 'Materialen en kleuren',
    link: '/concept/materialen-en-kleuren/',
    img: TileMaterial
  },
  {
    id: 3,
    title: 'Bijpassende riemen',
    link: '/concept/bijpassende-riem/',
    img: TileBelt
  },
  {
    id: 4,
    title: 'Thiago shoes Customs',
    link: '/concept/customs/',
    img: TileCustom
  }
]

const footerCols = [
  {
    col: 1,
    title: 'Adres',
    list: [
      { prompt: 'Ambiorixstraat 13', value: '', type: 'string' },
      { prompt: '6171 BH Stein', value: '', type: 'string' },
      { prompt: 'Nederland', value: '', type: 'string' }
    ]
  },
  {
    col: 2,
    title: 'Contact',
    list: [
      { prompt: 'Email', value: 'info@guilhermo.nl', type: 'mail' },
      { prompt: 'Phone', value: '+31 (0)6 290 851 85', type: 'phone' }
    ]
  },
  {
    col: 3,
    title: 'Informatie',
    list: [
      {
        prompt: 'Algemene Voorwaarden',
        value: '/informatie/algemene-voorwaarden/',
        type: 'link'
      },
      { prompt: 'Privacy', value: '/informatie/privacy/', type: 'link' }
    ]
  }
]

const slogan =
  'Exclusieve handgemaakte schoenen en riemen\n' +
  'uit Portugal voor de zakelijke markt'

const prefix = 'thiago'

const config = {
  siteName,
  slogan,
  tiles,
  footerCols,
  readMore,
  prefix
}

export default config
