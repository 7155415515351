import { useState } from 'react'
import { SubscribeNewsletterInterface } from './SubscribeNewsletter.interface'
import { Heading } from '../../atoms'

const SubscribeNewsletter = ({
  testID,
  type
}: SubscribeNewsletterInterface) => {
  const [email, setEmail] = useState('')
  const submitEMail = () => {}

  const renderInput = () => {
    return (
      <div className='input-group'>
        <input
          type='email'
          style={{ height: 40 }}
          name='email'
          placeholder='email*'
          value=''
        />
        <span>
          <button onClick={() => submitEMail()} type='button'>
            &gt;
          </button>
        </span>
      </div>
    )
  }

  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`SubscribeNewsletter`}
    >
      <Heading type={'h3'} text={'Nieuwsbrief'} />
      {renderInput()}
    </div>
  )
}

export default SubscribeNewsletter
