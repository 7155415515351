import { SpinnerInterface } from './Spinner.interface'
import Loader from 'react-spinners/PulseLoader'

const Spinner = ({ testID, color }: SpinnerInterface) => {
  return (
    <div data-testid={testID} className={`Spinner`}>
      <Loader color={color} />
    </div>
  )
}

export default Spinner
