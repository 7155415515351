import { FooterColumnInterface } from './FooterColumn.interface'
import { FooterListItem, Heading } from 'src/components/atoms'

const FooterColumn = ({
  testID,
  column,
  type,
  children
}: FooterColumnInterface) => {
  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`FooterColumn`}
    >
      <Heading type={'h3'} text={column.title} />
      <ul>
        {column.list.map((item: any, index: any) => {
          return (
            <li key={index}>
              <FooterListItem item={item} type={item.type} />
            </li>
          )
        })}
        <li>{children}</li>
      </ul>
    </div>
  )
}

export default FooterColumn
