import { useEffect, useState } from 'react'
import { collection, query, getDocs, where, orderBy } from 'firebase/firestore'
import { database } from '../config'

const useDatabase = (content: string): [any[], boolean] => {
  const [data, setData] = useState([])
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (content) {
      const fetchData = async () => {
        const qRef = collection(database, content)

        const q = query(
          qRef,
          where('publish', '==', true)
          //orderBy('sortOrder', 'asc')
        )
        const querySnapshot = await getDocs(q)
        const queryResults: any = []

        querySnapshot.forEach((doc) => {
          queryResults.push(doc.data())
        })
        setData(queryResults.sort((a: any, b: any) => a.order - b.order))
        setLoaded(true)
      }
      fetchData()
    }
  }, [])

  return [data, isLoaded]
}

export default useDatabase
