// import { useState, useEffect, useContext } from 'react'
import { LogoInterface } from './Logo.interface'
import { config } from '../../../config'
import logoImage from '../../../resources/images/logo.png'
import { Link } from 'react-router-dom'

const Logo = ({ testID, type = 'home' }: LogoInterface) => {
  return (
    <Link to={'/'}>
      <div
        data-testid={testID}
        data-object-type={type ?? ''}
        className={`Logo`}
      >
        <img src={logoImage} alt={config.siteName} />
      </div>
    </Link>
  )
}

export default Logo
