import { useState } from 'react'
import { ProductListInterface } from './ProductList.interface'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { Row, Col } from 'react-bootstrap'
import { ProductItem, Spinner } from 'src/components/atoms'
import { useDatabase } from 'src/hooks'

const ProductList = ({ testID, collection, type }: ProductListInterface) => {
  const [showLightBox, setShowLightBox] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [data, isLoaded] = useDatabase(collection)

  const setLight = (pos: number) => {
    setPhotoIndex(pos)
    setShowLightBox(true)
  }

  const renderProductList = () => {
    if (isLoaded) {
      return (
        <>
          <Row>
            {data
              ?.sort((a: any, b: any) => a.order - b.order)
              .map((item: any, index: number) => {
                return (
                  <Col
                    onClick={() => setLight(index)}
                    key={item.order}
                    md={6}
                    className={'ProductColumn'}
                  >
                    <ProductItem image={item.img} alt={item.title} />
                  </Col>
                )
              })}
          </Row>

          {showLightBox && (
            <Lightbox
              mainSrc={data[photoIndex]?.img}
              nextSrc={data[(photoIndex + 1) % data?.length]?.img}
              prevSrc={
                data[(photoIndex + data?.length - 1) % data?.length]?.img
              }
              onCloseRequest={() => setShowLightBox(false)}
              onMovePrevRequest={() =>
                setPhotoIndex(
                  (photoIndex) => (photoIndex + data?.length - 1) % data?.length
                )
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex) => (photoIndex + 1) % data?.length)
              }
            />
          )}
        </>
      )
    }
    return (
      <div className={'spinner-content'}>
        <Spinner color={'#adad13'} />
      </div>
    )
  }

  return (
    <div
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`ProductList`}
    >
      {renderProductList()}
    </div>
  )
}

export default ProductList
