import { SectionInterface } from './Section.interface'
import { Container, Row, Col } from 'react-bootstrap'
import { Heading, Paragraph } from 'src/components/atoms'

const Section = ({ testID, id, type, data, children }: SectionInterface) => {
  const renderContent = () => {
    if (type !== 'info') {
      return (
        <div className={'section-content'}>
          <Heading type={'h1'} text={data.title} />
          <Paragraph type={'markdown'} content={data.paragraph} />
        </div>
      )
    } else {
      return (
        <Row className={'section-content-dark'}>
          <Col md={5}>
            <img src={data.img} alt={data.title} />
          </Col>
          <Col md={6}>
            <div>
              <Heading type={'h1'} text={data.title} />
              <Paragraph type={'markdown'} content={data.paragraph} />
            </div>
          </Col>
        </Row>
      )
    }
  }
  return (
    <section
      id={id}
      data-testid={testID}
      data-object-type={type ?? ''}
      className={`Section`}
    >
      <Container>
        <Row>
          <Col md={1} />
          <Col md={10}>{data && renderContent()}</Col>
          <Col md={1} />
        </Row>
        {children && children}
      </Container>
    </section>
  )
}

export default Section
