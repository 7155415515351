// import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { MenuInterface } from './Menu.interface'
import { menu } from 'src/config'

const Menu = ({ testID, style, type, size, props }: MenuInterface) => {
  return (
    <div data-testid={testID} data-object-type={type ?? ''} className={`Menu`}>
      {menu.map((item: any, index: number) => {
        return <Link to={item.link}>{item.title}</Link>
      })}
    </div>
  )
}

export default Menu
